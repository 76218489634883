<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-5 shadow"
        >
          <div class="p-2 text-center" v-if="loadingLogs">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>

          <form v-else>
            <div class="row mb-4">
              <div class="col-12 mb-5">
                <button type="button" class="btn btn-dark btn-lg btn-block">
                  {{ lead.type.toUpperCase() }}
                </button>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    v-model="lead.name"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Contact Email</label>
                  <input
                    type="text"
                    id="email"
                    v-model="lead.email"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Contact Phone</label>
                  <input
                    type="text"
                    id="phone"
                    v-model="lead.phone"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="location">Location</label>
                  <input
                    type="text"
                    id="location"
                    v-model="lead.location"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 mt-5">
                <hr />

                <div class="row" id="actionBox">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">New Update</label>
                      <textarea
                        class="form-control"
                        v-model="update"
                        name=""
                        id=""
                        rows="3"
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-3">
                    <button
                      type="button"
                      @click.prevent="saveUpdate()"
                      class="btn btn-primary"
                      :disabled="loadingNewUpdate"
                    >
                      <b-spinner small v-if="loadingNewUpdate"></b-spinner>
                      <span v-else>
                        Save <i class="fa fa-paper-plane"></i
                      ></span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="timeline-simple">
                  <p class="timeline-title">Logs</p>

                  <div class="mt-3 text-center" v-if="loadingFetchAction">
                    <b-card class="">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="67%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                      <b-skeleton animation="wave" width="90%"></b-skeleton>
                      <b-skeleton animation="wave" width="95%"></b-skeleton>
                    </b-card>
                  </div>

                  <div
                    class="timeline-list my-4"
                    v-for="log in lead.logs"
                    :key="log.id"
                  >
                    <div class="timeline-post-content">
                      <div class="user-profile">
                        <img
                          :src="`${url}/uploads/images/${log.user.image}`"
                          class=""
                          alt="user image"
                        />
                      </div>
                      <div class="">
                        <h4>
                          {{ log.user.firstname }} {{ log.user.lastname }}
                        </h4>
                        <p class="meta-time-date">
                          {{ formatFancyDate(log.created_at) }}
                        </p>
                        <div class="">
                          <h6 class="">Update</h6>
                          <p class="post-text">{{ log.update }}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";

import { mapGetters } from "vuex";

export default {
  name: "BusinessDevelopmentLogs",

  data() {
    return {
      loading: false,

      loadingLogs: false,
      id: null,

      lead: "",

      update: "",

      loadingNewUpdate: false,
      loadingFetchAction: false,
    };
  },

  computed: {
    ...mapGetters(["url", "user"]),
  },

  mounted() {
    this.id = this.$route.params.id;
    console.log(this.id);
    this.fetchLead();
  },

  methods: {
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },

    fetchLead() {
      this.loadingLogs = true;
      http
        .get(endpoints.FETCH_SINGLE_LEAD.replace(":id", this.id))
        .then((response) => {
          this.lead = response;
          this.loadingLogs = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveUpdate() {
      if (this.update == "") {
        this.$toast.error("Update field is required. Please try again.");
      } else {
        this.loadingNewUpdate = true;
        http
          .post(endpoints.NEW_LEAD_UPDATE, {
            id: this.id,
            update: this.update,
          })
          .then((response) => {
            this.loadingNewUpdate = false;
            this.update = "";
            this.$toast.success(response);
            this.fetchLogs();
          })
          .catch((error) => {
            this.loadingNewUpdate = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    fetchLogs() {
      this.loadingFetchAction = true;
      http
        .get(endpoints.GET_LOGS_FOR_SINGLE_LEAD.replace(":id", this.id))
        .then((response) => {
          this.lead.logs = response;
          this.loadingFetchAction = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingFetchAction = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    roundUp(x) {
      return x.toPrecision(2);
    },
  },
};
</script>

<style
  scoped
  src="@/assets/css/components/timeline/custom-timeline.css"
></style>

<style scoped>
.new-control {
  font-weight: 400;
  font-size: 15px;
}
.tableInput {
  border: none !important;
  font-size: 14px;
}
</style>
